body {
  font-family: Tomorrow, sans-serif;
  font-size: 16px;
  color: $secondary;
  font-weight: 400;
  overflow-x: hidden;
  background-color: $black;
  @include lg {
    font-size: 14px;
  }
  @include sm {
    font-size: 13px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 12px;
  font-weight: 400;
  color: $secondary;
}

p {
  margin: 0;
  padding: 0;
  font-size: 16px;
  @include lg {
    font-size: 14px;
  }
}

ul,
ol {
  margin: 0;
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
  display: inline-block;
}

a {
  text-decoration: none;
  display: inline-block;
  color: $secondary;
}

input:focus {
  outline: 0 solid;
}

input {
  border: 1px solid #efefef;
  padding: 10px 15px;
  border-radius: 3px;
}

img {
  width: 100%;
  height: 100%;
}
