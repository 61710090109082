.abtn {
    padding: 17px 40px;
    font-size: 16px;
    background-color: $btnbgp1;
    color: $secondary;
    @include xxl {
        padding: 15px 40px;
    }
    @include lg {
        padding: 12px 35px;
        font-size: 15px;
    }
    @include sm {
        padding: 10px 29px;
        font-size: 14px;
    }
    @include ssm {
        padding: 8px 23px
    }
    @include transition;
}