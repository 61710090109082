.pcontainer {
    --contw: 150px;
    @include xxl {
        --contw: 125px;
    }
    @include xl {
        --contw: 100px;
    }
    @include lg {
        --contw: 80px;
    }
    @include sm {
        --contw: 20px;
    }
    @include ssm {
        --contw: 10px;
    }
    margin-left: var( --contw);
    margin-right: var(--contw);
}

.header {
    padding: 10px 0;
    border-bottom: 1px solid $borderp1;
    &-logo {
        width: 74px;
        height: auto;
        @include lg {
            width: 65px;
        }
        @include ssm {
            width: 60px;
        }
        &:hover {
            opacity: 0.7;
            @include transition;
        }
    }
}

.body {
    & .pcontainer {
        border-left: 1px solid $borderp1;
        border-right: 1px solid $borderp1;
        padding-bottom: 60px;
    }
    &-card {
        @include xxl {
            margin-bottom: 80px;
        }
        & a {
            color: #656467;
            margin-top: 25px;
            margin-bottom: 20px;
            font-family: 'inter';
            &:hover {
                color: #9995a0;
                @include transition;
            }
        }
        &-wrap {
            padding: 24px;
            background-color: $secondary;
            @include ssm {
                padding: 14px;
            }
            &-img {
                margin-bottom: 24px;
            }
            &-contant {
                &-head {
                    margin-bottom: 24px;
                    & h4 {
                        font-family: Tomorrow;
                        color: $white;
                        @include mb12;
                    }
                    & p {
                        color: $primaryp2;
                    }
                }
                &-bottom {
                    &-left {
                        border-right: 1px solid #5A227D;
                        width: 50%;
                        &-icon {
                            margin-bottom: 8px;
                            &-svg {
                                width: 32px;
                                height: 32px;
                                background-color: #201327;
                                margin-right: 12px;
                                border-radius: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                            & span {
                                font-weight: 600;
                                @include f18;
                                display: block;
                                color: #D025FD;
                            }
                        }
                        & p {
                            color: $primaryp2;
                            @include f16;
                        }
                    }
                    &-right {
                        & h4 {
                            @include f18;
                            font-weight: 600;
                            margin-bottom: 13px;
                            color: $white;
                        }
                        & p {
                            @include f16;
                            color: $primaryp2;
                        }
                    }
                }
            }
        }
    }
    &-pricing {
        margin-top: 115px;
        @include xxl {
            width: 400px !important;
            margin-top: 80px;
        }
        @include xl {
            margin-left: 40px;
        }
        @include md {
            margin-left: 0;
        }
        &-card {
            &-head {
                margin-bottom: 70px;
                align-items: end;
                @include xxl {
                    margin-bottom: 60px;
                }
                @include xl {
                    margin-bottom: 60px;
                }
                &-img {
                    width: 90px;
                    height: auto;
                    margin-right: 40px;
                }
                &-right {
                    & .fp {
                        color: $white;
                        @include mb12;
                    }
                    & p {
                        color: $primary;
                    }
                    & h2 {
                        color: $white;
                        font-size: 32px;
                        @include mb12;
                        @include xl {
                            font-size: 30px;
                        }
                        @include md {
                            font-size: 28px;
                        }
                        @include sm {
                            font-size: 24px;
                        }
                    }
                }
            }
            &-item {
                margin-bottom: 32px;
                &-single {
                    justify-content: space-between;
                    padding: 24px;
                    border-top: 1px solid $borderp1;
                    @include xxl {
                        padding: 15px;
                    }
                    @include xl {
                        padding: 20px;
                    }
                    & .lasth3 {
                        @include f24;
                        color: $white;
                        margin-bottom: 0;
                    }
                    &:last-child {
                        border-bottom: 1px solid $borderp1;
                    }
                    & p {
                        color: $primary;
                        display: flex;
                        align-items: center;
                        justify-content: end;
                    }
                    &-right {
                        &-wrap {
                            @include mb12;
                            align-items: center;
                            justify-content: end;
                            & h3 {
                                @include f24;
                                color: $white;
                                margin-bottom: 0;
                                margin-right: 4px;
                            }
                        }
                    }
                    &-left {
                        & p {
                            margin-right: 16px;
                        }
                    }
                }
            }
            &-button {
                @include md {
                    margin-bottom: 70px;
                }
                & a {
                    display: block;
                    padding: 23px 0;
                    color: $secondary;
                    background: #FDFDFD;
                    text-align: center;
                    @include transition;
                    font-weight: 500;
                    font-size: 18px;
                    position: relative;
                    z-index: 1;
                    &:hover {
                        color: $white;
                        &::before {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                    &::before {
                        background-image: linear-gradient(to right, #D422FD, #9834EF);
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: -1;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        visibility: hidden;
                        @include transition;
                        content: '';
                    }
                    @include xxl {
                        padding: 18px 0;
                    }
                    @include lg {
                        padding: 16px 0;
                    }
                    @include md {
                        font-size: 16px;
                    }
                    @include ssm {
                        font-size: 13px;
                    }
                }
            }
        }
    }
}