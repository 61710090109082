@import "../css/bootstrap.min.css";
@import "../scss/base/media_quary";
@import "../scss/utils/variables";
@import "../scss/utils/mixins";
@import "../scss/base/typography";
@import "../scss/base/reset";
@import "../scss/components/buttons";
@import "../scss/layout/home-page1";
@import "../scss/layout/home-page2";
@import "../scss/layout/home-page3";

.wallet-adapter-button,
.wallet-adapter-button-trigger {
  padding: 17px 40px;
  font-size: 16px;
  @include xxl {
    padding: 15px 40px;
  }
  @include lg {
    padding: 12px 35px;
    font-size: 15px;
  }
  @include sm {
    padding: 10px 29px;
    font-size: 14px;
  }
  @include ssm {
    padding: 8px 23px;
  }
  @include transition;
}

.wallet-adapter-modal li {
  display: list-item !important;
  text-align: -webkit-match-parent !important;
}

.key-indicators h1 {
  color: white;
  text-align: center;
}

.txHash {
  color: white;
}

.txHash:hover {
  color: aqua;
}

.header-right {
  display: flex;
  justify-content: end;
  align-items: center;
  flex-wrap: wrap;
  gap: 4rem;
}

@media (max-width: 576px) {
  .header-custom {
    text-align: center;
  }

  .header-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 4rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
}

.custom-card-item {
  .body-pricing-card-button {
    margin: 0;
  }
}
