.v3 {
  border: unset !important;
}

.pcontainer2 {
  --contw: 150px;
  @include xxl {
    --contw: 125px;
  }
  @include xl {
    --contw: 100px;
  }
  @include lg {
    --contw: 80px;
  }
  @include sm {
    --contw: 20px;
  }
  @include ssm {
    --contw: 10px;
  }
  margin-right: var(--contw);
  margin-left: var(--contw);
}

.parttan {
  background-image: url(../../img/Home3.png);
  background-size: cover;
  min-height: 100vh;
}

.banner {
  margin-top: 100px;
  margin-bottom: 50px;
  position: relative;
  @include xl {
    margin-top: 50px;
  }
  &-item {
    background-color: $secondary;
    padding: 0;
    overflow: hidden;
    &-single {
      padding: 24px 16px;
      border: 1px solid $secondary;
      @include transition;
      position: relative;
      z-index: 1;
      overflow: hidden;
      padding-bottom: 120px;
      &:hover {
        border-color: #6c33b4;
        .banner-item-single-contant {
          transform: translateY(0px);
        }
        // & .banner-item-single-contant-top {
        //     & h3 {
        //         font-size: 24px;
        //     }
        // }
        & .banner-item-single-img {
          & img {
            filter: grayscale(0);
          }
        }
        // & .banner-item-single-contant-bottom {
        //     display: block;
        //     margin-top: 30px;
        //     transition: 0.8s ease-in-out;
        // }
      }
      &-img {
        padding-bottom: 140%;
        width: 100%;
        position: relative;
        z-index: 1;
        overflow: hidden;
        img {
          filter: grayscale(100%);
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          width: 100%;
          height: auto;
          min-height: 100%;
          object-fit: cover;
        }
      }
      &-contant {
        position: absolute;
        left: 0;
        bottom: 0;
        width: calc(100% - (16px * 2));
        margin: 24px 16px;
        background-color: $secondary;
        transform: translateY(90px);
        z-index: 2;
        @include transition;
        &-top {
          padding-top: 32px;
          & h3 {
            font-size: 20px;
            font-weight: 600;
            color: $white;
            @include xl {
              font-size: 18px;
            }
          }
          & p {
            color: #9b9b9b;
            opacity: 0.7;
          }
        }
        &-bottom {
          margin-top: 30px;
          // position: absolute;
          // opacity: 0;
          // visibility: hidden;
          @include transition;
        }
      }
    }
  }
}

// brands
.brands {
  margin-bottom: 50px;
  --bs-gutter-x: 1.5rem;
  @include sm {
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
    padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
  }
  &-title {
    position: relative;
    z-index: 1;
    padding-bottom: 24px;
    margin-bottom: 32px;
    & img {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 32px;
      @include xxl {
        top: 1rem;
      }
      @include xl {
        top: 36px;
      }
    }
  }
  &-list {
    @include sm {
      gap: 30px;
    }
  }
  &-item {
    &:not(:last-child) {
      margin-right: 48px;
      @include lg {
        margin-right: 38px;
      }
      @include md {
        margin-right: 30px;
      }
      @include sm {
        margin-right: 0;
      }
    }
  }
}

// footer area start
.footer {
  padding-bottom: 50px;
  & .pcontainer2 {
    @media only screen and (max-width: 991px) {
      --contw: 0px;
    }
  }
  &-cont {
    background-color: #1f1f1f;
    padding: 40px 30px;
    @include md {
      padding: 35px 0px;
    }
  }
  &-item {
    display: flex;
    @include sm {
      flex-wrap: wrap;
    }
    &-single {
      display: flex;
      align-items: center;
      margin-right: 65px;
      @include xxl {
        margin-right: 40px;
      }
      @include xl {
        margin-right: 30px;
      }
      @include md {
        margin-right: 20px;
      }
      @include sm {
        margin-bottom: 20px;
      }
      &:last-child {
        margin-right: 0;
      }
      &-left {
        margin-right: 65px;
        @include xxl {
          margin-right: 40px;
        }
        @include xl {
          margin-right: 30px;
        }
        @include md {
          margin-right: 17px;
        }
        & p {
          @include f14;
          color: #9b9b9b;
          @include mb12;
        }
        &-icon {
          align-items: center;
          & span {
            @include f16;
            font-weight: 600;
            color: $white;
            margin-right: 14px;
          }
        }
        & h3 {
          color: $white;
          @include f16;
          font-weight: 600;
        }
      }
    }
  }
  &-button {
    @include lg {
      margin-top: 40px;
    }
    @include sm {
      margin-top: 10px;
    }
    & a {
      background-color: $btnbgp1;
      padding: 18px;
      text-align: center;
      display: inline-block;
      width: 100%;
      @include f18;
      @include transition;
      font-weight: 500;
      position: relative;
      z-index: 1;
      &:hover {
        color: $white;
        &::before {
          opacity: 1;
          visibility: visible;
        }
      }
      &::before {
        background-image: linear-gradient(to right, #d422fd, #9834ef);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        @include transition;
        content: "";
      }
    }
  }
}
