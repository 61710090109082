.body {
    &-sale {
        margin-top: 160px;
        @include xxl {
            margin-top: 106px;
        }
        @include xl {
            margin-left: 17px;
            margin-top: 76px;
        }
        @include lg {
            margin-left: 0;
        }
        &-item {
            margin-bottom: 48px;
            @include lg {
                margin-bottom: 30px;
            }
            &-single {
                background-color: $secondary;
                border: 1px solid #1F1F1F;
                padding: 32px 24px;
                margin-bottom: 32px;
                position: relative;
                z-index: 1;
                &:hover {
                    color: $white;
                    &::before {
                        opacity: 1;
                        visibility: visible;
                    }
                }
                &::before {
                    border: 1px solid #D422FD;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    visibility: hidden;
                    @include transition;
                    content: '';
                }
                &:hover {
                    & span {
                        background-color: #d422fd;
                    }
                }
                @include xl {
                    padding: 26px 14px;
                }
                &-top {
                    padding-right: 268px;
                    @include xxl {
                        padding-right: 100px;
                    }
                    @include xl {
                        padding-right: 55px;
                    }
                    @include sm {
                        padding-right: 90px;
                    }
                    & p {
                        @include f14;
                        color: #656467;
                        margin-bottom: 24px;
                        font-weight: 500;
                        @include sm {
                            margin-bottom: 18px;
                        }
                    }
                    & h3 {
                        font-size: 20px;
                        color: $white;
                        margin-bottom: 16px;
                        font-weight: 600;
                        @include lg {
                            font-size: 18px;
                        }
                    }
                }
                &-bottom {
                    & span {
                        width: 64.222px;
                        height: 16px;
                        background: #37323E;
                        margin-right: 4px;
                        transition: 0.6s ease-in-out;
                        &:last-child {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
    &-mintbtn {
        position: relative;
        @include lg {
            margin-bottom: 70px;
            padding: 16px 50px;
        }
        & .rc2 {
            background: $white;
            color: $secondary;
            padding: 18px 56px;
            @include f16;
            @include transition;
            position: relative;
            z-index: 1;
            padding: 18px 48px;
            &::before {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                background-image: linear-gradient(to right, #D422FD, #9834EF);
                opacity: 0;
                visibility: hidden;
                @include transition;
            }
            &:hover {
                color: $white;
                &::before {
                    opacity: 1;
                    visibility: visible;
                }
                // & span {
                //     opacity: 1;
                //     visibility: visible;
                //     z-index: 3;
                // }
            }
            // & span {
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     display: inline-block;
            //     background: $white;
            //     color: $secondary;
            //     // padding: 18px 56px;
            //     width: 100%;
            //     height: 100%;
            //     display: flex;
            //     align-items: center;
            //     justify-content: center;
            //     z-index: -1;
            //     opacity: 0;
            //     visibility: hidden;
            //     @include transition;
            //     @include f16;
            //     color: $white;
            // }
        }
    }
}